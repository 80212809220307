@import url('https://fonts.googleapis.com/css?family=Montserrat');

*:focus {
    outline: none;
}

.assignmentCheck .MuiSvgIcon-root {
    font-size: 18px;
    stroke-width: 1;
}

.assignmentCheck {
    width: 76px;
}

.MuiCheckbox-root {
    padding: 0 !important;
}

.editable {
    background: rgba(47, 120, 196, 0.1);
}
