.container {
    background: #fff;
}

.supplierName {
    padding: 6px 9px 4px 9px;
    font-weight: 400;
    text-align: left;
    font-size: 13px;
    background: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.checkWrap {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.whiteBackground {
    background: #fff;
}

.checkWrap > div {
    padding: 4px 9px;
    height: 100%;
    font-family: 'Selawik';
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
}

.headingPad {
    padding: 5px 9px 5px 9px;
}

.headingFont {
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.th {
    color: #212121;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    border-top: 1px solid #D3D2D2;
    border-bottom: 1px solid #D3D2D2;
    border-right: 1px solid #D3D2D2;
    padding: 5px 9px 5px 9px;
    word-break: break-all;
    background: #fff;
}

.th .MuiSvgIcon-root {
    font-size: 28px;
}

.fontSelawik {
    font-family: 'Selawik';
}
