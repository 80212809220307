.flex {
    display: flex;
}

.flex1 {
    flex: 1;
}

.row {
    flex-direction: row;
}

.col {
    flex-direction: column;
}

.flex1 {
    flex-grow: 1;
    flex-basis: 0;
}

.borderTop {
    border-top: 1px solid rgb(211, 210, 210);
}

.borderRight {
    border-right: 1px solid rgb(211, 210, 210);
}

.borderBottom {
    border-bottom: 1px solid rgb(211, 210, 210);
}

.borderLeft {
    border-left: 1px solid rgb(211, 210, 210);
}

.border {
    border: 1px solid rgb(211, 210, 210);
}

.contentCenter {
    justify-content: center;
}

.itemsCenter {
    align-items: center;
}
