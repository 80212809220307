.App {
  font-size: 12px;
}

.error-cell input,
.error-cell span {
  color: red;
}

.excluded {
  background-color: rgb(220, 220, 220);
}

#update-lot {
  display: none;
}

* {
  box-sizing: border-box;
}
