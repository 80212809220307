.table {
    font-size: 12px;
    background-color: #fff;
}

.row {
    height: 27px;
    text-align: center;
    padding: 5px 9px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.header::-webkit-scrollbar {
    display: none;
}
 
/* Hide scrollbar for IE, Edge and Firefox */
.header {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.headerCell {
    letter-spacing: -0.1px;
    color: #212121;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
}

.fontSelawik {
    font-family: 'Selawik';
}

.pad5 {
    padding: 5px 9px;
}

.padCell {
    padding: 6px 9px 4px 9px;
}

.awardCheckbox {
    padding-top: 3px;
}

.supplierName {
    height: 43px;
    padding: 5px 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.supplierGroup {
    padding: 0;
}

.supplierGroup div {
    height: 100%;
}

.supplierGroup div:not(:last-child) {
    border-right: 1px solid rgb(211, 210, 210);
}

.borderTop {
    border-top: 1px solid rgb(211, 210, 210);
}

.borderRight {
    border-right: 1px solid rgb(211, 210, 210);
}

.borderBottom {
    border-bottom: 1px solid rgb(211, 210, 210);
}

.borderLeft {
    border-left: 1px solid rgb(211, 210, 210);
}

.border {
    border: 1px solid rgb(211, 210, 210);
}

.contentCenter {
    justify-content: center;
}

.itemsCenter {
    align-items: center;
}

.pad0 {
    padding: 0;
}

.editable {
    background: rgba(47, 120, 196, 0.1);
}

.awardInput {
    background: transparent;
}

.bestBid {
    background-color: #369148;
    color: #fff;
}

.bestBid > span {
    color: #fff !important;
}

.totalCell {
    font-size: 13px;
}

.totalCell span {
    font-size: 13px;
    font-family: 'Selawik';
}

.suffix {
    padding-left: 6px;
}

.supplierValue {
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgb(211, 210, 210);
    border-bottom: 1px solid rgb(211, 210, 210);
    font-size: 13px;
    font-family: 'Selawik';
}

.headerNames {
    overflow: hidden;
}

.link {
    color: #2f78c4;
    cursor: pointer;
}

.cellContent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.classicColumn {
    width: 135px;
}
